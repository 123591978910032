import { TIME_START, TIME_STOP } from "actions/types";

const playing = (state = false, action) => {
  switch (action.type) {
    case TIME_START:
      return true;
    case TIME_STOP:
      return false;
    default:
      return state;
  }
};

export default playing;
