import bg1 from "images/background/1.jpeg";
import bg2 from "images/background/2.jpeg";
import bg3 from "images/background/3.png";
import bg4 from "images/background/4.png";
import bg5 from "images/background/5.png";

export const BACKGROUND_1 = { src: bg1, value: "BACKGROUND_1" };
export const BACKGROUND_2 = { src: bg2, value: "BACKGROUND_2" };
export const BACKGROUND_3 = { src: bg3, value: "BACKGROUND_3" };
export const BACKGROUND_4 = { src: bg4, value: "BACKGROUND_4" };
export const BACKGROUND_5 = { src: bg5, value: "BACKGROUND_5" };

const BACKGROUNDS = [
  BACKGROUND_1,
  BACKGROUND_2,
  BACKGROUND_3,
  BACKGROUND_4,
  BACKGROUND_5,
];

export default BACKGROUNDS;
