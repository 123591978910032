import { BACKGROUND_SET } from "actions/types";
import { BACKGROUND_3 } from "constant/backgrounds";

const initialState = BACKGROUND_3;

const background = (state = initialState, action) => {
  switch (action.type) {
    case BACKGROUND_SET:
      return action.payload;
    default:
      return state;
  }
};

export default background;
