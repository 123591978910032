import madrid from "images/madrid.png";

import madRozas from "images/mad-rozas.png";

import blue from "images/madrid-blue.png";
import red from "images/madrid-red.png";
import green from "images/madrid-green.png";

import escorial from "images/escorial.png";
import fenix from "images/fenix.png";
import guadarrama from "images/guadarrama.png";
import leganes from "images/leganes.png";
import lena from "images/lena.png";
import rozas from "images/rozas.png";
import valle from "images/valle.png";

export const RED = { icon: red, label: "ROJO" };
export const GREEN = { icon: green, label: "VERDE" };
export const BLUE = { icon: blue, label: "AZUL" };
export const YELLOW = { icon: madrid, label: "AMARILLO" };

export const UHK_MADRID = { icon: madrid, label: "UHK MADRID" };

export const MAD_ROZAS = { icon: madRozas, label: "MADRID-ROZAS" };

export const FENIX = { icon: fenix, label: "FENIX" };
export const LEGANES = { icon: leganes, label: "LEGANES" };
export const GUADARRAMA = { icon: guadarrama, label: "GUADARRAMA" };
export const ESCORIAL = { icon: escorial, label: "ESCORIAL" };
export const LENA = { icon: lena, label: "LENA" };
export const ROZAS = { icon: rozas, label: "ROZAS" };
export const VALLE = { icon: valle, label: "VALLE" };

const TEAMS = [
  UHK_MADRID,
  MAD_ROZAS,
  ESCORIAL,
  FENIX,
  GUADARRAMA,
  LEGANES,
  LENA,
  ROZAS,
  VALLE,
  RED,
  GREEN,
  BLUE,
  YELLOW,
];

export default TEAMS;
