import { combineReducers } from "redux";
import { withReduxStateSync } from "redux-state-sync";
import background from "./background";
import period from "./period";
import playing from "./playing";
import score from "./score";
import teams from "./teams";
import time from "./time";
import isDescending from "./isDescending";

const rootReducer = combineReducers({
  background,
  period,
  playing,
  score,
  teams,
  time,
  isDescending,
});

export default withReduxStateSync(rootReducer);
