export const TIME_INCREMENT = "TIME_INCREMENT";
export const TIME_DECREMENT = "TIME_DECREMENT";
export const TIME_SET = "TIME_SET";
export const TIME_START = "TIME_START";
export const TIME_STOP = "TIME_STOP";
export const TIME_IS_DESCENDING_SET = "TIME_IS_DESCENDING_SET";

export const PERIOD_SET = "PERIOD_SET";
export const PERIOD_SHOW_SET = "PERIOD_SHOW_SET";
export const PERIOD_LENGTH_SET = "PERIOD_LENGTH_SET";

export const SCORE_SET_LOCAL = "SCORE_SET_LOCAL";
export const SCORE_SET_VISITOR = "SCORE_SET_VISITOR";

export const TEAM_SET_LOCAL = "TEAM_SET_LOCAL";
export const TEAM_SET_VISITOR = "TEAM_SET_VISITOR";

export const BACKGROUND_SET = "BACKGROUND_SET";
