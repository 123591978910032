import useEventListener from "@use-it/event-listener";

export default function useKeyDownListener(
  keyCodes,
  onKeyDown,
  element = window
) {
  function handler({ keyCode, code }) {
    if (keyCodes.includes(String(code)) || keyCodes.includes(String(keyCode))) {
      onKeyDown();
    }
  }

  useEventListener("keydown", handler, element);
}
