import React from "react";
import ScoreboardUI from "components/scoreboard";
import Stopwatch from "components/controls/stopwatch";
import Teams from "components/controls/teams";
import ScoreboardControl from "components/controls/scoreboard";
import Background from "components/controls/background";
import Horn from "components/controls/horn";
import "./style.css";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stack,
  Button,
  CardHeader,
  Card,
  CardContent,
} from "@mui/material";
import {
  AccessAlarm,
  Scoreboard as ScoreboardIcon,
  SportsHockey,
  PhotoCameraBackSharp,
  ExpandMore,
  OpenInNew,
  Settings,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

const CONFIG_SECTIONS = [
  {
    component: <Background />,
    icon: <PhotoCameraBackSharp />,
    title: "Select Background",
  },
  {
    component: <Teams />,
    icon: <SportsHockey />,
    title: "Select Teams",
  },
  {
    component: <Horn />,
    icon: <AccessAlarm />,
    title: "Horn",
  },
];

const MATCH_SECTIONS = [
  {
    component: <Stopwatch />,
    icon: <AccessAlarm />,
    title: "Stopwatch",
  },
  {
    component: <ScoreboardControl />,
    icon: <ScoreboardIcon />,
    title: "Scoreboard",
  },
];

const Section = ({ component, icon, title }) => {
  return (
    <Card>
      <CardHeader avatar={icon} title={title} />
      <CardContent>{component}</CardContent>
    </Card>
  );
};

const Main = () => {
  return (
    <Stack
      direction={{
        xs: "column",
        lg: "row",
      }}
      style={{
        alignItems: "baseline",
        width: "100vw",
        minHeight: "100vh",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Stack
        spacing={1}
        sx={{
          padding: "2%",
          width: { xs: "100vw", lg: "calc(96% - 640px)" },
        }}
      >
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Stack direction="row" spacing={2}>
              <Settings />
              <Typography>Settings</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={1}>
              {CONFIG_SECTIONS.map((section) => (
                <Section key={section.title} {...section} />
              ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Stack direction="row" spacing={2}>
              <SportsHockey />
              <Typography>Match</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={1}>
              {MATCH_SECTIONS.map((section) => (
                <Section key={section.title} {...section} />
              ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Stack>

      <Stack spacing={2} style={{ maxHeight: "100vh" }}>
        <Stack direction="row" justifyContent="center">
          <Button onClick={() => {}} variant="outlined" endIcon={<OpenInNew />}>
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to="/scoreboard"
              target="_blank"
            >
              Open Scoreboard
            </Link>
          </Button>
        </Stack>
        <div
          className="main__container--scoreboard"
          style={{
            width: "640px",
            height: "360px",
          }}
        >
          <ScoreboardUI />
        </div>
      </Stack>
    </Stack>
  );
};

export default Main;
