import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import * as timeActions from "actions/time";
import { Button, Stack, Typography } from "@mui/material";
import useKeyDownListener from "util/useKeyDownListener";
import InitialTime from "./initialTime";
import Period from "./period";
import Switch from "components/switch";

const SPACEBAR_KEYS = ["32", "Space"];

const Stopwatch = ({
  incrementTime,
  decrementTime,
  setTime,
  startTime,
  stopTime,
  playing,
  isDescending,
  setTimeIsDescending,
}) => {
  const countRef = useRef(null);

  useEffect(() => {
    if (playing) {
      countRef.current = setInterval(
        isDescending ? decrementTime : incrementTime,
        1000
      );
    } else {
      clearInterval(countRef.current);
    }
  }, [playing, isDescending, decrementTime, incrementTime]);

  const handleStartStop = () => (playing ? stopTime() : startTime());

  const handleReset = (initialTime) => {
    stopTime();
    setTime(initialTime);
  };

  const handleDirectionChange = (value) => {
    stopTime();
    setTimeIsDescending(value);
  };

  useKeyDownListener(SPACEBAR_KEYS, handleStartStop);

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent={"space-around"}>
        {/* <MatchTime /> */}
        <InitialTime setTime={handleReset} />
      </Stack>
      <Stack direction="row" justifyContent={"space-around"}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>Ascending</Typography>
          <Switch value={isDescending} setValue={handleDirectionChange} />
          <Typography>Descending</Typography>
        </Stack>
        <Period />
      </Stack>
      <Button
        onClick={handleStartStop}
        color={playing ? "secondary" : "success"}
        size="large"
        variant="contained"
      >
        {playing ? "Stop" : "Start"}
      </Button>
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  playing: state.playing,
  isDescending: state.isDescending,
});

const mapDispatchToProps = {
  incrementTime: timeActions.incrementTime,
  decrementTime: timeActions.decrementTime,
  setTime: timeActions.setTime,
  startTime: timeActions.startTime,
  stopTime: timeActions.stopTime,
  setTimeIsDescending: timeActions.setTimeIsDescending,
};

export default connect(mapStateToProps, mapDispatchToProps)(Stopwatch);
