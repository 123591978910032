import cucaracha from "sounds/cucaracha.mp3";
import car from "sounds/car.mp3";
import truck from "sounds/truck.mp3";
import domingo from "sounds/domingo.m4a";

export const SOUND_CUCARACHA = {
  name: "Cucaracha",
  sound: cucaracha,
};
export const SOUND_DOMINGO = {
  name: "Domingo",
  sound: domingo,
};
export const SOUND_CAR = {
  name: "Car",
  sound: car,
};
export const SOUND_TRUCK = {
  name: "Truck",
  sound: truck,
};

const SOUNDS = [SOUND_CUCARACHA, SOUND_DOMINGO, SOUND_CAR, SOUND_TRUCK];

export default SOUNDS;
