import React from "react";
import { connect } from "react-redux";
import * as teamsActions from "actions/teams";
import { TextField, Autocomplete, Stack, Box } from "@mui/material";
import TEAM_OPTIONS from "constant/teams";

const TeamSelector = ({ inputLabel, value, setValue }) => {
  return (
    <Autocomplete
      sx={{ width: 300 }}
      options={TEAM_OPTIONS}
      autoHighlight
      getOptionLabel={(option) => option.label}
      value={value}
      onChange={(event, newValue) => setValue(newValue)}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img loading="lazy" width="20" src={option.icon} alt="" />
          {option.label}
        </Box>
      )}
      renderInput={(params) => <TextField {...params} label={inputLabel} />}
    />
  );
};

const Teams = ({ teams, setLocalTeam, setVisitorTeam }) => {
  return (
    <Stack direction="row" spacing={2} justifyContent="center">
      <TeamSelector
        inputLabel="Local Team"
        value={teams.local}
        setValue={setLocalTeam}
      ></TeamSelector>
      <TeamSelector
        inputLabel="Visitor Team"
        value={teams.visitor}
        setValue={setVisitorTeam}
      ></TeamSelector>
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  teams: state.teams,
});

const mapDispatchToProps = {
  setVisitorTeam: teamsActions.setVisitorTeam,
  setLocalTeam: teamsActions.setLocalTeam,
};

export default connect(mapStateToProps, mapDispatchToProps)(Teams);
