import { TIME_INCREMENT, TIME_DECREMENT, TIME_SET } from "actions/types";

const time = (state = 0, action) => {
  switch (action.type) {
    case TIME_INCREMENT:
      return state + 1;
    case TIME_DECREMENT:
      return state - 1;
    case TIME_SET:
      return action.payload;
    default:
      return state;
  }
};

export default time;
