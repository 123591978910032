import { PERIOD_LENGTH_SET, PERIOD_SET, PERIOD_SHOW_SET } from "./types";

export const setPeriod = (period) => ({
  type: PERIOD_SET,
  payload: period,
});

export const setPeriodLength = (length) => ({
  type: PERIOD_LENGTH_SET,
  payload: length,
});


export const setShowPeriod = (show) => ({
  type: PERIOD_SHOW_SET,
  payload: show,
});
