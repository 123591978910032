import { PERIOD_LENGTH_SET, PERIOD_SET, PERIOD_SHOW_SET } from "actions/types";
import { PERIOD_LENGTH_15_MIN } from "constant/match";

const initialState = {
  value: 1,
  show: false,
  length: PERIOD_LENGTH_15_MIN,
};

const period = (state = initialState, action) => {
  switch (action.type) {
    case PERIOD_SET:
      return { ...state, value: action.payload };
    case PERIOD_SHOW_SET:
      return { ...state, show: action.payload };
    case PERIOD_LENGTH_SET:
      return { ...state, length: action.payload };
    default:
      return state;
  }
};

export default period;
