import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import * as timeActions from "actions/time";
import usePrevious from "util/usePrevious";
import useSound from "use-sound";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { Campaign } from "@mui/icons-material";
import {
  SOUND_CAR,
  SOUND_CUCARACHA,
  SOUND_TRUCK,
  SOUND_DOMINGO,
} from "constant/sounds";

const soundOptions = [
  SOUND_TRUCK.name,
  SOUND_CAR.name,
  SOUND_DOMINGO.name,
  SOUND_CUCARACHA.name,
];

const Horn = ({ stopTime, time, playing, isDescending, periodLength }) => {
  const prevTime = usePrevious(time);

  // add sound options
  const [playTRUCK] = useSound(SOUND_TRUCK.sound, { volume: 1 });
  const [playCAR] = useSound(SOUND_CAR.sound, { volume: 1 });
  const [playDOMINGO] = useSound(SOUND_DOMINGO.sound, { volume: 1 });
  const [playCUCA] = useSound(SOUND_CUCARACHA.sound, { volume: 1 });
  const sounds = useMemo(
    () => ({
      [SOUND_TRUCK.name]: playTRUCK,
      [SOUND_CAR.name]: playCAR,
      [SOUND_DOMINGO.name]: playDOMINGO,
      [SOUND_CUCARACHA.name]: playCUCA,
    }),
    [playTRUCK, playCAR, playDOMINGO, playCUCA]
  );

  const [selectedSound, setSelectedSound] = useState(soundOptions[0]);

  const playSelectedSound = useCallback(() => {
    sounds[selectedSound]();
  }, [sounds, selectedSound]);

  useEffect(() => {
    if (
      (isDescending && time === 0) ||
      (!isDescending && time % periodLength === 0)
    ) {
      if (playing && prevTime !== time) {
        playSelectedSound();
        stopTime();
      }
    }
  }, [
    time,
    playing,
    prevTime,
    isDescending,
    periodLength,
    playSelectedSound,
    stopTime,
  ]);

  return (
    <Stack direction="row" spacing={2} justifyContent="center">
      <FormControl>
        <InputLabel>Sound</InputLabel>
        <Select
          value={selectedSound}
          label="Sound"
          onChange={(event) => {
            setSelectedSound(event.target.value);
          }}
        >
          {soundOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        onClick={playSelectedSound}
        variant="contained"
        color="error"
        endIcon={<Campaign />}
      >
        Play
      </Button>
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  time: state.time,
  playing: state.playing,
  isDescending: state.isDescending,
  periodLength: state.period.length,
});

const mapDispatchToProps = {
  stopTime: timeActions.stopTime,
};

export default connect(mapStateToProps, mapDispatchToProps)(Horn);
