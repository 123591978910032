import React from "react";
import { Switch, FormControlLabel } from "@mui/material";

const MySwitch = ({ label, setValue, value }) => (
  <FormControlLabel
    control={
      <Switch
        checked={value}
        onChange={(event) => {
          setValue(event.target.checked);
        }}
      />
    }
    label={label}
  />
);

export default MySwitch;
