import React from "react";
import { connect } from "react-redux";
import * as periodActions from "actions/period";
import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  OutlinedInput,
  Stack,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { PERIOD_LENGTH_10_MIN, PERIOD_LENGTH_15_MIN } from "constant/match";

const Period = ({
  period,
  setPeriod,
  showPeriod,
  setShowPeriod,
  periodLength,
  setPeriodLength,
}) => (
  <Stack direction="row" spacing={1} alignItems="center">
    <FormControl>
      <InputLabel>Period nº</InputLabel>
      <Select
        value={period}
        onChange={(event) => {
          setPeriod(event.target.value);
        }}
        size="small"
        labelId="period-label"
        sx={{ width: 80 }}
        input={<OutlinedInput label="Period" />}
      >
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
      </Select>
    </FormControl>
    <FormControlLabel
      control={
        <Checkbox
          checked={showPeriod}
          onChange={(event) => setShowPeriod(event.target.checked)}
        />
      }
      label="Show"
    />
    <FormControl>
      <InputLabel>Period length</InputLabel>
      <Select
        value={periodLength}
        onChange={(event) => {
          setPeriodLength(event.target.value);
        }}
        size="small"
        labelId="period-label"
        sx={{ width: 150 }}
        input={<OutlinedInput label="Period" />}
      >
        <MenuItem value={PERIOD_LENGTH_15_MIN}>15 min</MenuItem>
        <MenuItem value={PERIOD_LENGTH_10_MIN}>10 min</MenuItem>
      </Select>
    </FormControl>
  </Stack>
);

const mapStateToProps = (state) => ({
  period: state.period.value,
  showPeriod: state.period.show,
  periodLength: state.period.length,
});

const mapDispatchToProps = {
  setPeriod: periodActions.setPeriod,
  setShowPeriod: periodActions.setShowPeriod,
  setPeriodLength: periodActions.setPeriodLength,
};

export default connect(mapStateToProps, mapDispatchToProps)(Period);
