import {
  TIME_DECREMENT,
  TIME_INCREMENT,
  TIME_IS_DESCENDING_SET,
  TIME_SET,
  TIME_START,
  TIME_STOP,
} from "./types";

export const incrementTime = () => ({
  type: TIME_INCREMENT,
});

export const decrementTime = () => ({
  type: TIME_DECREMENT,
});

export const setTime = (time) => ({
  type: TIME_SET,
  payload: time,
});

export const startTime = () => ({
  type: TIME_START,
});

export const stopTime = () => ({
  type: TIME_STOP,
});

export const setTimeIsDescending = (value) => ({
  type: TIME_IS_DESCENDING_SET,
  payload: value,
});
