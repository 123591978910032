import { TEAM_SET_LOCAL, TEAM_SET_VISITOR } from "./types";

export const setLocalTeam = (team) => ({
  type: TEAM_SET_LOCAL,
  payload: team,
});

export const setVisitorTeam = (team) => ({
  type: TEAM_SET_VISITOR,
  payload: team,
});
