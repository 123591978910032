import React from "react";
import { Button, Stack, TextField } from "@mui/material";
import isEmpty from "util/isEmpty";
import { useForm } from "react-hook-form";

const InitialTime = ({ setTime }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = ({ minutes, seconds }) => {
    setTime(Number(minutes) * 60 + Number(seconds));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" spacing={2} justifyContent="center">
        <TextField
          label="Minutes"
          type="number"
          size="small"
          sx={{ width: 80 }}
          error={!!errors.minutes}
          helperText={errors.minutes && "[0..99]"}
          defaultValue={0}
          {...register("minutes", { min: 0, max: 99 })}
        />

        <TextField
          label="Seconds"
          type="number"
          size="small"
          sx={{ width: 80 }}
          error={!!errors.seconds}
          helperText={errors.seconds && "[0..59]"}
          defaultValue={0}
          {...register("seconds", { min: 0, max: 59 })}
        />

        <Button
          variant="outlined"
          color="error"
          type="submit"
          disabled={!isEmpty(errors)}
        >
          Update Time
        </Button>
      </Stack>
    </form>
  );
};

export default InitialTime;
