import { TEAM_SET_LOCAL, TEAM_SET_VISITOR } from "actions/types";
import { UHK_MADRID } from "constant/teams";

const initialState = { local: UHK_MADRID, visitor: UHK_MADRID };

const teams = (state = initialState, action) => {
  switch (action.type) {
    case TEAM_SET_LOCAL:
      return {
        ...state,
        local: action.payload,
      };
    case TEAM_SET_VISITOR:
      return {
        ...state,
        visitor: action.payload,
      };
    default:
      return state;
  }
};

export default teams;
