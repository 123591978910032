import { SCORE_SET_LOCAL, SCORE_SET_VISITOR } from "actions/types";

const initialState = { local: 0, visitor: 0 };

const score = (state = initialState, action) => {
  switch (action.type) {
    case SCORE_SET_LOCAL:
      return {
        ...state,
        local: action.payload,
      };
    case SCORE_SET_VISITOR:
      return {
        ...state,
        visitor: action.payload,
      };
    default:
      return state;
  }
};

export default score;
