import React from "react";
import { connect } from "react-redux";
import * as backgroundActions from "actions/background";
import { useSelect } from "@mui/base";
import { styled } from "@mui/material/styles";
import { Stack, ButtonBase } from "@mui/material";
import BACKGROUND_OPTIONS from "constant/backgrounds";

const Background = ({ background, setBackground }) => {
  useSelect({
    value: background,
    options: BACKGROUND_OPTIONS,
  });

  return (
    <Stack direction="row" spacing={2} justifyContent="center">
      {BACKGROUND_OPTIONS.map((option) => {
        return (
          <ImageButton
            onClick={() => setBackground(option)}
            focusRipple
            key={option.value}
            style={{
              width: "213px",
              height: "120px",
            }}
          >
            <ImageSrc style={{ backgroundImage: `url(${option.src})` }} />
            {option.value !== background.value && (
              <ImageBackdrop className="MuiImageBackdrop-root" />
            )}
          </ImageButton>
        );
      })}
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  background: state.background,
});

const mapDispatchToProps = {
    setBackground: backgroundActions.setBackground,
};

export default connect(mapStateToProps, mapDispatchToProps)(Background);

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 200,
  [theme.breakpoints.down("sm")]: {
    width: "100% !important",
    height: 100,
  },
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.15,
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
});

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create("opacity"),
}));
