import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { getMinutes, getSeconds } from "util/time";
import Team from "./team";
import Box from "./box";
import "./style.css";

const Scoreboard = ({
  background,
  time,
  period,
  showPeriod,
  localScore,
  visitorScore,
  localTeam,
  visitorTeam,
}) => {
  const score = `${localScore}:${visitorScore}`;
  return (
    <div
      className="scoreboard"
      style={{ backgroundImage: `url(${background.src})` }}
    >
      <div className="scoreboard__column scoreboard__column--team">
        <Box className="team">
          <Team {...localTeam} />
        </Box>
      </div>
      <div className="scoreboard__column scoreboard__column--main">
        {/* <Box>{title}</Box> */}
        <Box
          className={classNames("score", { "score--small": score.length > 4 })}
        >
          {score}
        </Box>
        <Box className="timer">
          {getMinutes(time)}:{getSeconds(time)}
        </Box>
        {showPeriod && <Box className="period">{period}º</Box>}
      </div>
      <div className="scoreboard__column scoreboard__column--team">
        <Box className="team">
          <Team {...visitorTeam} />
        </Box>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  background: state.background,
  time: state.time,
  period: state.period.value,
  showPeriod: state.period.show,
  localScore: state.score.local,
  visitorScore: state.score.visitor,
  localTeam: state.teams.local,
  visitorTeam: state.teams.visitor,
});

export default connect(mapStateToProps)(Scoreboard);
