import { TIME_IS_DESCENDING_SET } from "actions/types";

const isDescending = (state = false, action) => {
  switch (action.type) {
    case TIME_IS_DESCENDING_SET:
      return action.payload;
    default:
      return state;
  }
};

export default isDescending;
