import React from "react";
import { connect } from "react-redux";
import * as scoreActions from "actions/score";
import { TextField, Stack } from "@mui/material";

const Scoreboard = ({ score, setLocalScore, setVisitorScore }) => {
  return (
    <Stack direction="row" spacing={2} justifyContent="center">
      <TextField
        label="Local"
        type="number"
        sx={{ width: 80 }}
        color="success"
        value={score.local}
        onChange={(event) => {
          setLocalScore(event.target.value);
        }}
        focused
      />

      <TextField
        label="Visitor"
        type="number"
        sx={{ width: 80 }}
        color="success"
        value={score.visitor}
        onChange={(event) => {
          setVisitorScore(event.target.value);
        }}
        focused
      />
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  score: state.score,
});

const mapDispatchToProps = {
  setVisitorScore: scoreActions.setVisitorScore,
  setLocalScore: scoreActions.setLocalScore,
};

export default connect(mapStateToProps, mapDispatchToProps)(Scoreboard);
