import { SCORE_SET_LOCAL, SCORE_SET_VISITOR } from "./types";

export const setLocalScore = (score) => ({
  type: SCORE_SET_LOCAL,
  payload: score,
});

export const setVisitorScore = (score) => ({
  type: SCORE_SET_VISITOR,
  payload: score,
});
