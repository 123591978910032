import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import reducer from "reducers";
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
} from "redux-state-sync";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const initialState = {};
const config = {};
const middlewares = [createStateSyncMiddleware(config)];
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middlewares))
);
initStateWithPrevTab(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
